import React, { Component } from 'react';
import { FormControl, FormGroup, Form } from 'react-bootstrap';

export class SearchBar extends Component {
    render() {
        return (
            <Form>
                <FormGroup className="margin-bottom-0" >
                    <FormControl
                        type="text"
                        placeholder="Pesquisar..."
                        onChange={undefined === this.props.onSearch ? null : (a) => this.props.onSearch(a.target.value)} />
                </FormGroup>
            </Form>
        );
    }
}