import React, { Component } from 'react';

export class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404 - Página não encontrada</h1>
                <div className="row">
                    <div className="col-sm-12">
                        <span>Não há nada para ver aqui. :(</span>
                    </div>
                </div>
            </div>
        );
    }
}
