import React, { Component } from 'react';
import { Modal, Card, Button } from 'react-bootstrap'

export class HandleMessage extends Component {
    renderHeaderIcon() {
        const { icon } = this.props;

        if (undefined === icon || null === icon || "" === icon) {
            return null;
        }

        return (
            <div className={`float-left ${icon}`}></div>
        );
    }

    renderButton() {
        const { noButtons } = this.props;

        if (noButtons) {
            return null;
        }

        const { buttonText } = this.props

        return (
            <div className="float-right">
                <Button variant="outline-dark" onClick={this.props.onHide}> {buttonText || "OK"} </Button>
            </div>
        );
    }

    render() {
        const { show, style, title, message, small, large } = this.props;

        if (!show) {
            return null;
        }

        const messageLines = [];
        if ("string" === typeof message) {
            message.split("\n").map((msg, i) => messageLines.push(<div key={`message-${i}`}>{msg}</div>));
        }
        else {
            messageLines.push(<div key="message-line">{message}</div>);
        }

        return (
            <Modal
                size={small ? "sm" : (large ? "lg" : null)}
                onHide={this.props.onHide}
                show={true} >
                <Modal.Body className="padding-0" >
                    <Card className="margin-bottom-0 border-0">
                        <Card.Header className={style}><h3 className="alert-title">{this.renderHeaderIcon()}&nbsp;{title}</h3></Card.Header>
                        <Card.Body>
                            <div>
                                <div className="padding-bottom-20 font-size-12 break-word">
                                    {messageLines}
                                </div>
                                {this.renderButton()}
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        );
    }
}
