import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export class TableButton extends Component {
    renderIcon(icon) {
        if(undefined === icon) {
            return <div style={{cursor: "default"}} className="icon-img">&nbsp;</div>
        }

        return (
            <div className={`icon-img ${icon}`}></div>
        );
    }

    render() {
        const { className, marginRight, icon, title, disabled, onClick } = this.props;
        const callback = "function" === typeof onClick ? onClick : null;

        return (
            <Button title={undefined !== title ? title : ""} 
                size="sm" 
                variant="light"
                className={`table-btn ${marginRight ? "table-btn-margin-right" : ""} ${undefined !== className ? className : ""}`} 
                disabled={disabled}
                onClick={callback} >
                {this.renderIcon(icon)}
            </Button>
        );
    }
}
