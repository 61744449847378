import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { PaginationHelper } from '../helpers/PaginationHelper';
import { SearchHelper } from '../helpers/SearchHelper';
import { DocumentsCommand } from './commands/DocumentsCommand';
import { LoadingSpinner } from '../LoadingSpinner';
import { HandleMessage } from '../HandleMessage';
import { TitleRow } from '../TitleRow';
import { SearchBar } from '../SearchBar';
import { TableButton } from '../TableButton';

export class Schedules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: {
                data: [],
                filter: ""
            },
            actions: {
                isLoading: true
            },
            commands: {
                documentsCommand: new DocumentsCommand()
            },
            message: {
                show: false,
                message: undefined,
                style: undefined,
                icon: undefined,
                title: ""
            }
        };
    }

    componentDidMount() {
        this.getDocumentsList();
    }

    // -----------------------

    getDocumentsList() {
        const { match: { params } } = this.props;
        const { commands } = this.state;

        commands.documentsCommand.list(
            params.stopCode,
            (a) => this.listSuccessCallback(a),
            (a) => this.errorCallback(a, true)
        );
    }

    // -----------------------

    listSuccessCallback(result) {
        const { table, actions } = this.state;

        const totalPages = PaginationHelper.getTotalPages(result.length, table.elementsPerPage);

        actions.isLoading = false;
        table.data = result;
        if (0 !== totalPages) {
            table.page = table.page > totalPages ? totalPages : table.page;
        }
        else {
            table.page = 1;
        }

        // Update table
        this.setState({
            table: table,
            actions: actions
        });
    }

    errorCallback(message, reset = false) {
        const { table, actions } = this.state;

        actions.isLoading = false;
        if (reset) {
            table.page = 1;
            table.data = [];
        }

        this.setState({
            table: table,
            actions: actions,
            message: {
                show: true,
                message: message,
                style: "danger",
                title: "Erro",
                icon: "icon-error"
            }
        });
    }

    // -----------------------

    onExitMessage() {
        this.setState({
            message: {
                show: false,
                message: undefined,
                style: undefined,
                icon: undefined,
                title: ''
            }
        });
    }

    onSearch(filter) {
        const { table } = this.state;

        table.filter = filter;

        this.setState({
            table: table
        });
    }

    // -----------------------

    sortData(a, b) {
        var o1 = a.line;
        var o2 = b.line;

        var p1 = a.name;
        var p2 = b.name;

        if (undefined !== o1 && undefined !== o2 && o1.toLowerCase() < o2.toLowerCase()) return -1;
        if (undefined !== o1 && undefined !== o2 && o1.toLowerCase() > o2.toLowerCase()) return 1;
        if (undefined !== p1 && undefined !== p2 && p1.toLowerCase() < p2.toLowerCase()) return -1;
        if (undefined !== p1 && undefined !== p2 && p1.toLowerCase() > p2.toLowerCase()) return 1;
        return 0;
    }

    // -----------------------

    renderDownloadLink(model) {
        if (undefined === model.url || null === model.url || "" === model.url) {
            return null;
        }

        return (
            <TableButton
                icon="icon-download"
                onClick={() => window.open(model.url, "_blank")}
                title="Transferir"
            />
        );
    }

    renderTable() {
        const { actions } = this.state;

        if (actions.isLoading) {
            return (
                <LoadingSpinner />
            );
        }

        const { table } = this.state;

        const dataFiltered = SearchHelper.search({
            filter: table.filter,
            data: table.data,
            fields: ["code", "name", "line", "orientation", "generationDate"]
        });

        return (
            <Container className="margin-top-15" fluid>
                <Row>
                    <Col sm={12}>
                        {dataFiltered.sort((a, b) => this.sortData(a, b)).map((data, idx) =>
                            <Container className="margin-top-15" fluid>
                                <Row>
                                    <Col>
                                        <p>[{data.code}] {data.name} - {undefined === data.line ? "«Desconhecida»" : data.line} [{data.orientation}]</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img alt="" className="schedule-img" key={`schedule-${idx}`} src={data.url} />
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        const { message } = this.state;

        return (
            <div>
                <TitleRow title="Horários" showSeparator={true}>
                    <Col className="padding-0" xs={12} sm={12} md={{ span: 8, offset: 4 }}>
                        <div className="search-bar">
                            <SearchBar onSearch={(a) => this.onSearch(a)} />
                        </div>
                    </Col>
                </TitleRow>

                {this.renderTable()}

                <HandleMessage
                    title={message.title}
                    message={message.message}
                    style={message.style}
                    icon={message.icon}
                    show={message.show}
                    onHide={() => this.onExitMessage()} />
            </div>
        );
    }
}
