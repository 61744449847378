import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound';
import { Schedules } from './components/documents/Schedules';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Switch>
          <Route path='/schedules/:stopCode' component={Schedules} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}
