export class SearchHelper {
    // Object Struct to use the method
    // { filter: "", data: [], fields: [], callbackComparison: {"field": callback(fieldValue)}, boolComparison: { "filterText": "field" }, valueParser: { "field": { "value1": "compare1", "value2": "compare2" } } }
    static search(args) {
        const { filter, data, fields, callbackComparison, boolComparison, valueParser } = Object.assign({ filter: "", data: [], fields: [], callbackComparison: [], boolComparison: [], valueParser: [] }, args);

        if (undefined === filter || "" === filter) {
            return data;
        }

        const upperBoolComparison = {};
        for (let idx in boolComparison) {
            upperBoolComparison[`${idx}`.toUpperCase()] = boolComparison[idx];
        }

        if (undefined === fields || 0 === fields.length) {
            try {
                return data.filter(function (element) {
                    if (undefined === element || "" === element) {
                        return false;
                    }

                    return `${element}`.toUpperCase().includes(filter.toUpperCase());
                });
            }
            catch (_) {
                return data;
            }
        }

        return data.filter(function (element) {
            for (let idx in fields) {
                const fieldProperties = `${fields[idx]}`.split(".");

                let filteredElement = element;
                for (let field in fieldProperties) {
                    filteredElement = filteredElement[fieldProperties[field]];
                    if (undefined === filteredElement) {
                        break;
                    }
                }

                if (undefined !== filteredElement) {
                    const field = fieldProperties.slice(-1)[0];
                    const callbackComparisonObject = callbackComparison.find(f => field in f);

                    if (undefined === valueParser[field]) {
                        if ("boolean" === typeof filteredElement) {
                            let comparisionField = upperBoolComparison[filter.toUpperCase()];
                            if (undefined === comparisionField) {
                                comparisionField = filter;
                            }

                            if (field.toUpperCase() === comparisionField.toUpperCase() && filteredElement) {
                                return true;
                            }
                        }
                        else if (undefined !== callbackComparisonObject && "function" === typeof callbackComparisonObject[field]) {
                            if (`${callbackComparisonObject[field](filteredElement)}`.toUpperCase().includes(filter.toUpperCase())) {
                                return true;
                            }
                        }
                        else if (`${filteredElement}`.toUpperCase().includes(filter.toUpperCase())) {
                            return true;
                        }
                    }
                    else if (undefined !== valueParser[field] && undefined !== valueParser[field][filteredElement] && `${valueParser[field][filteredElement]}`.toUpperCase().includes(filter.toUpperCase())) {
                        return true;
                    }
                }
            }

            return false;
        });
    }
}
