import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';

export class LoadingSpinner extends Component {
    render() {

        return (
            <div className="loading">
                <ScaleLoader color="#4189C7" />
            </div>
        )
    }
} 
