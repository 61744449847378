import React, { Component } from 'react';
import { ButtonToolbar, Container, Row, Col } from 'react-bootstrap';

export class TitleRow extends Component {
    static defaultProps = {
        showSeparator: true,
        showBackground: false,
        element: "h1"
    };

    render() {
        var { title, children, showSeparator, showBackground, element } = this.props;
        
        return (
            <Container fluid>
                <Row className={`title-row title-row-with-${element} ${showSeparator ? "separator" : ""} ${showBackground ? "background" : ""}`}>
                    <Col sm={12} md={6} className="padding-0">
                        {title !== null ? React.createElement(element, {}, title) : <p style={{ lineHeight: 2 }}></p>}
                    </Col>
                    {children !== undefined ?
                        <Col sm={12} md={6} className={undefined === children.length ? "title-row-col-btns padding-0" : "padding-0" }>
                            <ButtonToolbar className="width-100p" >
                                {children}
                            </ButtonToolbar>
                        </Col>
                        : null}
                </Row>
            </Container>
        )
    }
}