import { apiFilesBaseURL } from '../../authentication/Global';
import { API, HTTPMethod, ContentType } from '../APICentral';

export class DocumentsAPI {
    constructor() {
        this.documentsApiRoutes =  {
            list: new URL("documents/", apiFilesBaseURL),
            download: new URL("documents/", apiFilesBaseURL)
        };
    }

    list(stopCode) {
        return API.authenticatedRequest({
            url: new URL(stopCode, this.documentsApiRoutes.list),
            method: HTTPMethod.GET,
            contentType: ContentType.JSON
        });
    }

    download(providerId, documentName) {
        return API.authenticatedRequest({
            url: new URL(`download/${providerId}/${documentName}`, this.documentsApiRoutes.download),
            method: HTTPMethod.GET,
            contentType: ContentType.JSON
        }, false);
    }
}
