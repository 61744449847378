import { DocumentsAPI } from '../../../api/documents/DocumentsAPI';

export class DocumentsCommand {
    constructor() {
        this.documentsApi = new DocumentsAPI();
    }

    list(stopCode, successCallback, errorCallback) {
        const apiResult = this.documentsApi.list(stopCode);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch(() => {
            if (undefined !== errorCallback) {
                errorCallback("Ocorreu um erro a ler os documentos");
            }
        });
    }

    download(name, model, successCallback, errorCallback) {
        const apiResult = this.documentsApi.download(model.provider, name);
        apiResult.then(async (result) => {
            if (undefined !== successCallback) {
                successCallback(await result.blob(), name);
            }
        }).catch((_) => {
            if (undefined !== errorCallback) {
                errorCallback("Ocorreu um erro a transferir o ficheiro.");
            }
        });
    }
}
